<template>
  <div v-if="voucher" class="voucher-container">
    <img v-if="bg" class="bg" :src="bg.value.url" />
    <div class="voucher">
      <img class="logo" src="@/assets/images/logo-white.svg" alt="" />
      <div class="top">
        <h1>Darčekový poukaz</h1>
      </div>
      <img class="dots" src="@/assets/images/voucher/dots.svg" alt="" />
      <div class="middle">
        <div class="box">
          <div class="midd-section">
            <div class="left">
              <div class="img">
                <img v-if="small" :src="small.value.url" alt="" />
                <img v-else-if="bg" :src="bg.value.url" />
              </div>
            </div>
            <div class="right">
              <h2 v-if="product && product.category">{{ product.category.name }}</h2>
              <div class="params">
                <div class="param-icon">
                  <img src="@/assets/images/voucher/lokalita.svg" alt="" />
                </div>
                <div class="param-name">Lokalita:</div>
                <div v-if="product && product.location" class="param-value">
                  {{ product.location.airport }} <!-- TODO toto sa meni kategoria od kategorie -->
                </div>
              </div>
              <div class="params">
                <div class="param-icon">
                  <img src="@/assets/images/voucher/dlzka.svg" alt="" />
                </div>
                <div class="param-name">Dĺžka letu:</div>
                <div class="param-value">
                  {{ flightTime }}
                </div>
              </div>
              <div class="params">
                <div class="param-icon">
                  <img src="@/assets/images/voucher/osoby.svg" alt="" />
                </div>
                <div class="param-name">Počet osôb:</div>
                <div class="param-value">
                  {{ passengers }}
                </div>
              </div>
              <div class="params validity">
                <div class="param-icon">
                  <img src="@/assets/images/voucher/kalendar.svg" alt="" />
                </div>
                <div class="param-name">Platnosť:</div>
                <div class="param-value">24.12.2022</div>
              </div>
            </div>
            <div class="left">
              <table>
                <tbody>
                  <tr>
                    <td>Kód poukazu:</td>
                    <td><span>{{ voucher.code }}</span></td>
                  </tr>
                  <tr>
                    <td>Heslo:</td>
                    <td><span>1437</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="dedication" class="right">
              <div class="note">
                <strong>Venovanie</strong>
                <p>{{ dedication }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img class="dots" src="@/assets/images/voucher/dots.svg" alt="" />
      <div class="bottom">
        <div class="box">
          <img class="qr-code" src="@/assets/images/voucher/qr.svg" alt="" />
          <div class="text-box narrow">
            <h3>AKTIVÁCIA POUKAZU?</h3>
            <p>
              Váš poukaz si aktivujete na
              https://www.LeteckeZazitky.sk/aktivacia-poukazu/, alebo
              prostredníctvom QR kódu napravo. Po Aktivácií poukazu obdržíte
              bližšie informácie a kontakt na Vám prideleného pilota, s ktorým
              si dohodnete presný deň a čas realizácie zážitku.
            </p>
          </div>

          <div class="text-box">
            <h3>REZERVÁCIA TERMÍNU?</h3>
            <p>
              Pri objednávke termínu uveďte Vaše meno, dĺžku letu a kód poukazu.
              Heslo poukazu nahláste až na letisku pri jeho využití. Na letisko
              sa dostavte minimálne 15 minút pred dohodnutým časom odletu.
              Poukaz predložte pilotovi na kontrolu. Trasa letu závisí od
              trvania letu, trasu je možné dohodnúť pred odletom s pilotom. Pri
              nedostavení sa na letisko v dohodnutom čase, poukaz stráca
              platnosť. V prípade zlého počasia budete informovaný o náhradnom
              termíne. Poukaz je možné použiť iba jeden krát.
            </p>
          </div>

          <h3>OBMEDZENIA:</h3>
          <div class="blue-box">
            <div class="param">
              <div class="icon">
                <img src="@/assets/images/voucher/kalendar.svg" alt="" />
              </div>
              <div class="info">
                Sezóna <br />
                {{ season }}
              </div>
            </div>
            <div class="param">
              <div class="icon">
                <img src="@/assets/images/voucher/vaha.svg" alt="" />
              </div>
              <div class="info">
                Váhový limit <br />
                {{ weight }}
              </div>
            </div>
            <div class="param">
              <div class="icon">
                <img src="@/assets/images/voucher/osoby.svg" alt="" />
              </div>
              <div class="info">
                Minimálny vek <br />
                {{ age }}
              </div>
            </div>
            <div class="param">
              <div class="icon">
                <img src="@/assets/images/voucher/komunikacia.svg" alt="" />
              </div>
              <div class="info">
                Komunikácia <br />
                {{ language }}
              </div>
            </div>
          </div>
          <div class="copy">
            Letecké Zážitky, s.r.o. | obchod@leteckezazitky.sk |
            www.LeteckeZazitky.sk | <strong>Infolinka 0908 770 702</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      bg: null,
      small: null,
      voucher: null,
      product: null,
      variant: null,
      seasonParamId: 4,
      weightParamId: 7,
      ageParamId: 6,
      languageParamId: 9,
      flightTimeParamId: 2,
      passengersParamID: 3,
      dedicationId: 28,
      season: '',
      weight: '',
      age: '',
      language: '',
      flightTime: '',
      passengers: '',
      dedication: ''
      // TODO zaznam, bonus, QR, heslo
    };
  },
  created() {
    this.loadVoucher();
  },
  methods: {
    loadVoucher() {
      const id = this.$route.params.id;
      this.$Vouchers.getResource({ id }, 'voucher_list').then((response) => {
        this.voucher = response.data;

        const orderItem = this.voucher.order.items.find(item => item.productVariant === this.voucher.productVariant);
        if (orderItem && orderItem.productConfigurations) {
          const dedication = orderItem.productConfigurations.find(c => c.id === this.dedicationId)
          if (dedication && dedication.description) {
            this.dedication = dedication.description
          }
        }

        this.$ProductVariants
          .getResourceByUrl({ url: `${this.voucher.productVariant}` })
          .then((variantResponse) => {
            this.variant = variantResponse.data;

            this.variant.customParameters.forEach(param => {
              if(param.customParameter.id === this.seasonParamId) {
                this.season = param.name
              }
              if(param.customParameter.id === this.weightParamId) {
                this.weight = param.name
              }
              if(param.customParameter.id === this.ageParamId) {
                this.age = param.name
              }
              if(param.customParameter.id === this.languageParamId) {
                this.language = param.name
              }
              if(param.customParameter.id === this.flightTimeParamId) {
                this.flightTime = param.name
              }
              if(param.customParameter.id === this.passengersParamID) {
                this.passengers = param.name
              }
            });

            this.$Products
              .getResourceByUrl({ url: `${this.variant.product}` })
              .then((productResponse) => {
                this.product = productResponse.data;

                if (this.product.category) {
                  this.$CustomFields.getCollection({ params: { name: ['voucher_bg_image', 'voucher_small_image'], ownerId: this.product.category.id, ownerResource: 'CATEGORY', embedCustomFields: true }}).then(cfResponse => {
                    this.bg = cfResponse.data['hydra:member'].find(cf => cf.name === 'voucher_bg_image')
                    this.small = cfResponse.data['hydra:member'].find(cf => cf.name === 'voucher_small_image')
                  })
                }
              });
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
body,
html {
  font-family: "Montserrat", sans-serif;
  width: 595px;
  height: 841.5px;
  color: #000;
  .voucher-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .voucher {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    .logo {
      position: absolute;
      top: 35px;
      right: 35px;
      width: 115px;
      height: auto;
    }
    .dots {
      display: block;
      margin: 1px auto;
    }
    .top {
      height: 279px;
      display: flex;
      align-items: center;
      padding: 0 32px;
      h1 {
        font-size: 62px;
        line-height: 56px;
        color: #fff;
        margin: 0;
        width: 50%;
        font-weight: 700;
      }
    }
    .middle {
      height: 273px;
      padding: 5px 15px;
      .midd-section {
        padding: 10px 15px 20px 15px;
        display: flex;
        flex-wrap: wrap;
        .left {
          width: 210px;
          margin-right: 15px;
          .img {
            width: 100%;
            height: 147px;
            margin-bottom: 10px;
            margin-top: 20px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          table {
            tr {
              td:first-child {
                font-size: 10px;
                text-align: right;
                color: #970c10;
                font-weight: 700;
              }
              td:last-child {
                text-align: center;
                width: 110px;
                padding: 5px;
                span {
                  color: #fff;
                  font-size: 10px;
                  line-height: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                  height: 22px;
                  background: #056fe4;
                  font-weight: 700;
                }
              }
            }
          }
        }
        .right {
          width: calc(100% - 225px);
          display: flex;
          flex-direction: column;
          h2 {
            color: #970c10;
            font-size: 19px;
            margin-bottom: 15px;
            text-align: center;
            font-weight: 700;
          }
          .params {
            display: flex;
            align-items: center;
            font-size: 9px;
            font-weight: 700;
            margin-bottom: 5px;
            &.validity {
              margin-top: auto;
              margin-bottom: 12px;
              .param-value {
                color: #970c10;
                font-size: 10px;
              }
            }
            .param-icon {
              width: 15px;
              height: 15px;
              margin-right: 10px;
            }
            .param-name {
              width: 55px;
              margin-right: 10px;
            }
          }
          .note {
            width: 100%;
            padding: 5px 20px;
            background: #eff8fd;
            font-size: 9px;
            height: 100%;
            strong {
              display: block;
              margin-bottom: 3px;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .bottom {
      height: 277px;
      padding: 5px 15px;
      .box {
        padding: 15px 15px 20px 15px;
        position: relative;
        h3 {
          font-size: 11px;
          margin-bottom: 5px;
          font-weight: 700;
        }
        .text-box {
          &.narrow {
            max-width: 320px;
          }
          p {
            font-size: 9px;
            margin-bottom: 10px;
            line-height: 12px;
          }
        }
        .qr-code {
          position: absolute;
          top: 15px;
          right: 15px;
        }
        .blue-box {
          width: 100%;
          background: #056fe4;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 15px;
          font-size: 9px;
          margin-bottom: 8px;
          .param {
            display: flex;
            align-items: center;
            .icon {
              margin-right: 5px;
              width: 15px;
              height: 15px;
              img {
                filter: brightness(0) invert(1);
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }
            .info {
              font-size: 9px;
              line-height: 11px;
            }
          }
        }
        .copy {
          font-size: 9px;
          text-align: center;
        }
      }
    }
    .box {
      position: relative;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.82);
    }
  }
}
</style>
